<template>
  <v-dialog v-model="dialog" fullscreen transition="slide-x-reverse-transition">
    <!-- origin="center right" -->
    <toolbar
      :back-button="backButton"
      color="secondary"
      :height="56"
      :stretch="stretchToolbar"
    >
      <template v-slot:title v-if="title">
        <slot name="title"> </slot>
        {{ title }}
      </template>
      <slot name="toolbar"> </slot>
      <v-btn v-if="forthButton" icon @click="$router.replace({ name: 'store' })"
        ><v-icon large>mdi-arrow-right</v-icon></v-btn
      >
      <v-divider
        :style="`border-width: 1px;
        background: ${getBorderColor};
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;`"
      />
    </toolbar>
    <div
      class="white"
      :style="`height: calc(100% - ${getViewHeightStyle(
        56
      )}px); overflow-y: auto;`"
    >
      <slot></slot>
      <v-fab-transition v-if="!!fab" style="right: 10px">
        <v-btn
          class="btn--fab mx-3"
          :color="fabColor || 'primary'"
          dark
          bottom
          right
          fab
          fixed
          small
          @click="$emit('fab:click')"
        >
          <v-icon>{{ typeof fab === "string" ? fab : "mdi-plus" }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </v-dialog>
</template>

<script>
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "FullView",
  components: { Toolbar },
  props: {
    title: String,
    stretchToolbar: Boolean,
    fabColor: String,
    fab: Boolean | String,
    fabSmall: Boolean,
    backButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    forthButton: {
      type: Boolean,
      default() {
        return false;
      },
    },
    headerHeight: {
      type: Number,
      default() {
        return 56;
      },
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
};
</script>

<style scoped>
.router-animation-enter-active {
  animation: coming 0.5s;
  animation-delay: 0.5s;
  opacity: 0;
}
.router-animation-leave-active {
  animation: going 0.5s;
}

@keyframes going {
  from {
    /** transform: translateX(0); **/
  }
  to {
    /** transform: translateX(-50px); **/
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
