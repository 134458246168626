import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","transition":"slide-x-reverse-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('toolbar',{attrs:{"back-button":_vm.backButton,"color":"secondary","height":56,"stretch":_vm.stretchToolbar},scopedSlots:_vm._u([(_vm.title)?{key:"title",fn:function(){return [_vm._t("title"),_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true}:null],null,true)},[_vm._t("toolbar"),(_vm.forthButton)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.replace({ name: 'store' })}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),_c(VDivider,{style:(("border-width: 1px;\n      background: " + _vm.getBorderColor + ";\n      position: absolute;\n      bottom: 0px;\n      left: 0;\n      right: 0;"))})],2),_c('div',{staticClass:"white",style:(("height: calc(100% - " + (_vm.getViewHeightStyle(
      56
    )) + "px); overflow-y: auto;"))},[_vm._t("default"),(!!_vm.fab)?_c(VFabTransition,{staticStyle:{"right":"10px"}},[_c(VBtn,{staticClass:"btn--fab mx-3",attrs:{"color":_vm.fabColor || 'primary',"dark":"","bottom":"","right":"","fab":"","fixed":"","small":""},on:{"click":function($event){return _vm.$emit('fab:click')}}},[_c(VIcon,[_vm._v(_vm._s(typeof _vm.fab === "string" ? _vm.fab : "mdi-plus"))])],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }